<template>
  <div class="main">
    <div class="top">
      <div class="home" @click="backhome()">
        <img src="@/assets/images/homebtn.png" alt="">
      </div>
      <div class="title">
        <img src="@/assets/images/connect.png" alt="">
      </div>
      <div class="log">

        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <!-- <div class="slogan">
        <img src="@/assets/images/wenzi.png" alt="" />
      </div> -->
      <div class="content">
        <p>小红书：熊猫在学</p>
        <p>抖音：熊猫在学</p>
        <p>视频号：熊猫在学-PandaWords</p>



      </div>
      <div class="juese">
         <img src="@/assets/images/juese.png" alt=""  />
      </div>

    </div>

    <div class="footer">
      <div class="phone">
        <!-- <img src="@/assets/images/fff.png" alt="" /> -->
        <div class="connect">
          <p>联系我们</p>
          官方QQ群：712390854
        </div>
        <div class="logoSmall">
          <img src="@/assets/images/ic_launcher(3).png" alt="" />
          <p>熊猫在学</p>
        </div>
        <div class="touch">
          <img src="@/assets/images/企业微信截图.png" alt="" />
          <p>联系合作</p>
        </div>
      </div>
      <div class="cominfo">
        <div class="company">
          <div class="text2">
          趣味背词体验，伙伴召唤系统。益智单词闯关，排行激励机制。合理安排时间，享受健康生活。在熊猫在学，遇见美好自己
          </div>
          <div class="text1">
            如果你在产品过程中，如遇到任何问题或意见，请通过以下方式联系我们，让我们解决与完善，熊猫在学愿陪伴你一起越来越好~
          </div>

          <div class="right">
            <a
              href="https://beian.miit.gov.cn"
              >鄂ICP证：鄂B2-20200287
            </a>
            |
            <a href="https://beian.miit.gov.cn"
              >鄂ICP备19017498号</a
            >

          </div>
            <div style="cursor:pointer"> <span>©盘达学趣（武汉）科技有限公司 - </span> <span @click="jump()">关于我们</span> | <span @click="toConnect()">联系方式</span> </div>
          <p >工商注册地址：武汉东湖新技术开发区关东街道高新二路北辰光谷里二期8幢8号楼A单元24层元空间088 </p>

        </div>
      </div>
    </div>
    <div class="mobail">
      <div class="top2">
        <div class="homebtn" @click="backhome()">
          <img src="@/assets/images/homebtn.png" alt="">
        </div>
        <div class="aboutTitle">
          <img src="@/assets/images/connect.png" alt="">
        </div>
        <div class="logo2">
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="contentmb">

  <p>小红书：熊猫在学</p>
        <p>抖音：熊猫在学</p>
        <p>视频号：熊猫在学-PandaWords</p>

        </div>


        <div class="juese">

          <img src="../assets/images/yidongduan/juese.png" alt="" />
        </div>
      </div>

      <div class="footer2">

        <div class="info">
          <div class="box">
            <div>官方QQ群：712390854</div>
            <div class="link">
              <a
                href="https://beian.miit.gov.cn"
                >鄂ICP证：鄂B2-20200287
              </a>
              <a href="https://beian.miit.gov.cn"
                >鄂ICP备19017498号</a
              >
            </div>
            <div><span @click="jump()">关于我们</span> | <span>联系方式</span></div>
            <p>©盘达学趣（武汉）科技有限公司</p>
             <p >工商注册地址：武汉东湖新技术开发区关东街道高新二路北辰光谷里二期8幢8号楼A单元24层元空间088 </p>
            <!-- <div>{{ $device }}</div> -->
          </div>
          <div class="box">
            <img src="../assets/images/yidongduan/qrcode.png" alt="" />
          </div>
        </div>
        <div>
          <!-- <img src="@/assets/images/fff.png" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "words",
  data() {
    return {

    };


},
  methods: {
    jump(){
      this.$router.push('/about')

    },
    toConnect(){
       this.$router.push('/connect')

    },
    backhome(){
       this.$router.push('/')

    },



  },



}
</script>


<style lang="scss" scoped>
@media screen and (min-width: 901px) {
  @keyframes bounce-down {
    25% {
      transform: translateY(-8px);
    }
    50%,
    100% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(8px);
    }
  }
  @keyframes bounce-up {
    25% {
      transform: translateY(8px);
    }
    50%,
    100% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-8px);
    }
  }

  .mobail {
    display: none;
  }
  .main {
    width: 100%;
    // height: auto;

    .top {
      width: 100%;
      height: 980rem;
      // position: absolute;
      background-image: url("~@/assets/images/bg1.png");
      background-size: 100% 100%;
      overflow: hidden;
      .home{
        width: 140rem;
        height: 40rem;
        margin: 10rem 0 0 20rem;
        img{
          width: 100%;
        }
      }
      .title{
        width: 200rem;
        height: 50rem;
        margin: 20rem auto 0;
        img {
          width: 100%;
        }
      }
      .log {
        width: 200rem;
        height: 15rem;
        margin: 50rem auto 0;
        img {
          width: 100%;
        }
      }
      .slogan {
        width: 423rem;
        height: 47rem;
        margin: 50 auto 0;
        img {
          width: 100%;
        }
      }



    }
    .content{
      width: 400rem;
      height: 400rem;
      margin: 200rem auto 0;
      // background-color: pink;
      font-family: KingnamMaiyuanBeta;
      color: #7b5431;
        font-size: 22rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28rem;
        text-indent:20rem;
        letter-spacing: 0rem;

        p{
        width: 400rem;
        text-align: center;
          margin-top: 40rem;

        }
    }
    .juese{
       width: 1920rem;
        height: 355rem;
      img{
        width: 110%;
          margin-top: -100rem;
            margin-left: -125rem;
            animation: bounce-down 2s linear infinite;

      }
    }


    .footer {
      width: 100%;
      height: 276rem;
      // margin-top: 10rem;
      // position: absolute;
      // top: 5300rem;
      background-color: #e4b582;
      a {
        text-decoration: none;
        color: #fff;
      }
      .cominfo {
        background-color: #e4b582;
        width: 100%;
        height: 220rem;
        .company {
          width: 1288rem;
          margin: 0 auto;
          background-color: #e4b582;
          color: #fff;
          // font-family: KingnamMaiyuanBeta;
          font-size: 19rem;
          line-height: 38rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .text2 {
            width: 1100rem;
            height: 18rem;
            margin-bottom: 10rem;
            text-align: center;
            white-space: nowrap;
          }
          .text1 {
            white-space: nowrap;
              text-align: center;
          }
          .logo {
            height: 71rem;
            margin-top: 30rem;

            .logoPic {
              img {
                vertical-align: middle;
              }

              span {
                line-height: 70rem;
                height: 70rem;
                margin-left: 7rem;
                margin-right: 20rem;
              }
            }
            .right {
              margin-left: 20rem;
            }
          }
          // .address{
          //   width: 100%;

          // }
        }
      }
      .phone {
        width: 100%;
        height: 333rem;
        background-color: #e4b582;
        div {
          font-size: 36rem;
          font-family: KingnamMaiyuanBeta;
          line-height: 38rem;
          color: #7b5431;
          width: 370rem;
          height: 200rem;
          position: absolute;
          margin: 98rem 200rem 0 150rem;
          p {
            margin-bottom: 40rem;
          }
        }
        .logoSmall {
          margin-left: 1200rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
        .touch {
          margin-left: 1480rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .main {
    .top {
      display: none;
    }
    .introduce {
      display: none;
    }
    .more {
      display: none;
    }
    .footer {
      display: none;
    }

    .mobail {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: #fae8ba;
      .top2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-image: url(../assets/images/yidongduan/beijing.png);
        background-repeat: no-repeat;
        background-size: cover;
        // background-size: contain;
        .homebtn{
          width: 500rem;
          height: 120rem;
          margin: 50rem 0 0 -1300rem;
          img{
            width: 100%;
          }
        }
        .aboutTitle{
          width: 600rem;
          height: 200rem;
          img{
            width: 100%;
          }
        }
        .logo2 {
          margin-top: calc(20rem * 2.56);
          img {
            width: calc(300rem * 2.56);
            object-fit: contain;
          }
        }
        .slogan2 {
          margin-top: calc(20rem * 2.56);
          img {
            width: calc(350rem * 2.56);
            object-fit: contain;
          }
        }
        .contentmb{
          width: 1600rem;
          height: 600rem;
          margin-bottom: 20rem;

           font-family: KingnamMaiyuanBeta;
      color: #7b5431;
        font-size: 60rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 80rem;
        text-indent:60rem;
        letter-spacing: 0rem;

          p{

            text-align: center;
            margin-top: 40rem;

          }

        }

        .juese {
          display: flex;
          flex-direction: column;
          margin-top: 80rem;
          animation: bounce-up 2s linear infinite;

          @keyframes bounce-up {
            25% {
              transform: translateY(8px);
            }
            50%,
            100% {
              transform: translateY(0);
            }
            75% {
              transform: translateY(-8px);
            }
          }
          .duiHuaKuang {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image: url(../assets/images/yidongduan/duihuakuang.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: calc(454rem * 2.56 * 0.75);
            height: calc(308rem * 2.56 * 0.75);
            align-self: flex-end;
            margin-right: 50rem;
            div {
              // font-size: 40rem;
              margin: 10rem;
              color: #e46060;
            }
          }
          img {
            width: calc(750rem * 2.56);
            object-fit: contain;
          }
        }
      }
      .introduce2,
      .footer2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        // background-color: #fae8ba;
        .page {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-top: 130rem;
          // background-color: #fae8ba;
          img {
            width: calc(160rem * 2.56);
            object-fit: contain;
          }
          p {
            margin: 0 26rem;
            font-family: KingnamMaiyuanBeta;
            font-size: 100rem;
            color: #7b5431;
          }
        }
        .page1 {
          .msgBox {
            display: flex;
            margin-top: 150rem;
            width: 100%;
            .part1 {
              background-image: url(../assets/images/yidongduan/di.png);
              background-repeat: no-repeat;
              background-size: contain;
              width: 1000rem;
              height: 1000rem;
              padding: 100rem;
              box-sizing: border-box;
              .item {
                display: flex;
                &:nth-child(2) {
                  margin-top: 100rem;
                }
                img {
                  width: 50rem;
                  object-fit: contain;
                  align-self: flex-start;
                  margin-top: 10rem;
                }
                .msg {
                  display: flex;
                  flex-direction: column;
                  font-family: KingnamMaiyuanBeta;
                  font-size: 60rem;
                  color: #7b5431;
                }
              }
            }
            .part2 {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 800rem;
                object-fit: contain;
              }
            }
          }
        }
        .page2 {
          .msgBox {
            .part1 {
              margin-left: 50rem;
            }
          }

          .part2 {
            // margin-right: 50rem;
            margin-left: 100rem;
          }
        }
        .page4 {
          .msgBox {
            // background-color: red;

            .my-swipe {
              // width: 1920rem;
              // width: 100%;
              // background-color: red;
              .van-swipe-item {
                display: flex;
                justify-content: center;

                // background-color: #39a9ed;

                img {
                  // width: 1000rem;
                  width: 70%;
                  // height: 800rem;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
      .footer2 {
        margin-top: 440rem;
        background-image: url(../assets/images/yidongduan/beijing2.png);
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        .msgBox22 {
          margin-top: 150rem;
          .my-swipe {
            width: 1920rem;
            // width: 100%;
            // background-color: red;
            .van-swipe-item {
              display: flex;
              justify-content: center;

              // background-color: #39a9ed;

              img {
                // width: 1000rem;
                width: 70%;
                // height: 800rem;
                object-fit: contain;
              }
            }
          }
        }
        .info {
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            margin: 150rem 10rem 10rem 20rem;

            display: flex;
            flex-direction: column;
            // align-items: center;
            font-size: 60rem;
            padding-bottom: 100rem;
            .link {
              display: flex;
              flex-direction: column;
            }
            a {
              text-decoration: none;
              color: #fff;
            }
            img {
              width: 400rem;
              object-fit: contain;
              margin-left: 20rem;
            }
          }
        }
      }
    }
  }
}
</style>
