<template>
  <div class="main">
    <div class="pc">
    <div class="header">
         <div class="home" @click="backhome()">
        <img src="@/assets/images/homebtn.png" alt="">
      </div>
        <div class="logo">
            <img src="@/assets/images/logo.png" alt="">
           </div>
    </div>
    <div class="content">
        <div class="contentBox">


        <div class="contentTitle">
            <h1>为什么这款刚上线就成为App Store教育榜冠军的APP更适合你？</h1>
        </div>
        <div class="contentaddTime">发布于 2023-07-25 18:44</div>
        <div class="contentView" >
            <p class="juzhong">号外号外❗❗❗</p>
             <p class="juzhong">近日🐼熊猫村出特大新闻了！！</p>
              <p class="juzhong">村里的小伙伴们齐聚一堂庆祝这一时刻</p>
               <p class="juzhong">🎉🎉🎉🎉🎉</p>
                <p >近日，App Store付费榜单显示，熊猫在学在教育类榜单中排名处于领先位置且用户反馈好评不断，更是在App Store被打出均分4.8的高分。</p>
                <div class="juzhong">
                    <img src="@/assets/images/weixin/dengding.png" alt="">
                </div>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/dengding2.png" alt="">
                </div>
                <p class="juzhong">熊猫在学是家长激励孩子学习的法宝</p>
                <p class="juzhong">更是陪伴许多小朋友长大的英语神器</p>
                <p class="juzhong">不费爸妈，不焦头烂额，不花大钱上线下课</p>
                <p class="juzhong">轻轻松松打好英语基础</p>
                <h3 class="juzhong">快来看看可爱的达宝们怎么评价它吧</h3>
                <h5 class="juzhong">👇👇👇</h5>
                  <div class="juzhong">
                    <img src="@/assets/images/weixin/kankui1.jpg" alt="">
                    <img src="@/assets/images/weixin/fankui2.jpg" alt="">
                </div>
                <h3 class="juzhong">App Store评价：有趣、寓教于乐</h3>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/pinjia1.jpg" alt="">
                </div>
                 <p class="juzhong">就算看到这么多好评</p>
                  <p class="juzhong">你可能还是会有疑虑：真的就这么好用吗❓</p>
                   <p class="juzhong">不试试真的亏大发了</p>
                    <p class="juzhong">用了才知道学习英语简直不要太开心❗❗</p>
                    <h3 class="juzhong">👇👇👇</h3>
                    <div class="juzhong">
                    <img src="@/assets/images/weixin/logo.jpg" alt="">
                </div>
                 <h3 class="juzhong">高效记词</h3>
                 <p>熊猫在学的学习流程系统。每个词都有英选汉、汉选英、听音辨义、读词训练、单词拼写五个步骤，包括单词助记环节，app内根据艾宾浩斯记忆曲线为用户定制每日复习内容防止遗忘，一轮下来记熟单词小意思。
</p>
<div class="juzhong">
                    <img src="@/assets/images/weixin/daci.jpg" alt="">
                </div>
                 <h3 class="juzhong">单词随身听</h3>
                 <p>错词随身听，让你随时随地进入英语状态，轻松培养语感。分为错词、新词、生词三个板块，自动循环播放单词及其对应的例句，充分利用碎片化时间。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/suishenting.jpg" alt="">
                </div>
                 <h3 class="juzhong">任务机制

</h3>
                 <p>完成每日学习任务即可领取奖励，奖励可以用来购买家具装饰小屋还能解锁召唤新伙伴。每个孩子都喜欢被给予反馈，及时的正向反馈能成为孩子学习的持久动力源泉。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/renwu1.jpg" alt="">
                </div>
                 <h3 class="juzhong">Penny外教</h3>
                 <p class="juzhong">Penny外教帮翻译、纠语法、练口语、串词成句，成为你最信赖的老师。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/ailaos.png" alt="">
                     <!-- <img src="@/assets/images/weixin/ailaos2.jpg" alt=""> -->
                </div>
                  <h3 class="juzhong">趣味闯关</h3>
                 <p class="juzhong">英语趣味闯关，在游戏的过程中巩固所学单词，将学习与休闲相结合，打造轻松愉快学英语的环境。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/huoban.png" alt="">

                </div>
                <div class="juzhong">
                    <img src="@/assets/images/weixin/youxi.jpg" alt="">

                </div>
                 <h2 class="juzhong">产品理念</h2>
                 <p>熊猫在学是一款主张学习＋正向激励的单词app，提倡通过多元化学习的形式把教育寄予在乐趣里，带给人趣味体验和益处。给用户带来高效记单词体验的同时，通过做任务、装饰小屋、趣味闯关等各个环节激励用户保持持久的学习劲头，逐渐将学习英语养成一种习惯。从痛苦乏味转为轻松有趣,从被动学习转为主动探索，让用户真正体验到学习的乐趣所在！</p>
                   <div class="juzhong">
                    <img src="@/assets/images/weixin/logo2.png" alt="">

                </div>
                 <p class="juzhong">学习从来都不是死记硬背</p>
                  <p class="juzhong">不是应付考试，不是迫于无奈</p>
                   <p class="juzhong">记单词不是根本目的</p>
                    <p class="juzhong">而是在寻找适合自己记忆方法的道路上</p>
                    <h3 class="juzhong">不断提高对英语的学习兴趣</h3>
                    <p class="juzhong">在学习中获得愉悦感和成就感</p>
                    <p class="juzhong">好的结果便会接踵而至😊😊😊</p>
                       <div class="juzhong">
                    <img src="@/assets/images/weixin/liule.jpg" alt="">

                </div>
                 <p class="juzhong">想亲身体验学习乐趣的宝子们</p>
                    <p class="juzhong">不要再犹豫啦❗❗</p>
                    <p class="juzhong">快来熊猫村一起学习玩耍吧</p>
                    <p class="juzhong">👏👏👏👏👏</p>
                    <h3 class="juzhong">
                        扫码下载app,学好英语很容易
                    </h3>
                     <div class="juzhong">
                    <img src="@/assets/images/weixin/xiazaima.png" alt="">

                </div>
                <h3 class="juzhong">
                        加入vx答疑群,变身熊猫小村民
                    </h3>
                     <div class="juzhong">
                    <img src="@/assets/images/weixin/weixinma.png" alt="">

                </div>

                <h3 class="juzhong">QQ群｜712390854</h3>
                  <h3 class="juzhong">小红书｜熊猫在学</h3>
                    <h3 class="juzhong">熊猫在学,有你更美好</h3>
                          <div class="juzhong">
                    <img src="@/assets/images/yidongduan/juese.png" alt="">

                </div>





        </div>
 </div>
    </div>
     <div class="footer">
      <div class="phone">
        <!-- <img src="@/assets/images/fff.png" alt="" /> -->
        <div class="connect">
          <p>联系我们</p>
          官方QQ群：712390854
        </div>
        <div class="logoSmall">
          <img src="@/assets/images/ic_launcher(3).png" alt="" />
          <p>熊猫在学</p>
        </div>
        <div class="touch">
          <img src="@/assets/images/企业微信截图.png" alt="" />
          <p>联系合作</p>
        </div>
      </div>
      <div class="cominfo">
        <div class="company">
          <div class="text2">
          趣味背词体验，伙伴召唤系统。益智单词闯关，排行激励机制。合理安排时间，享受健康生活。在熊猫在学，遇见美好自己
          </div>
          <div class="text1">
            如果你在产品过程中，如遇到任何问题或意见，请通过以下方式联系我们，让我们解决与完善，熊猫在学愿陪伴你一起越来越好~
          </div>

          <div class="right">
            <a
              href="https://beian.miit.gov.cn"
              >鄂ICP证：鄂B2-20200287
            </a>
            |
            <a href="https://beian.miit.gov.cn"
              >鄂ICP备19017498号</a
            >

          </div>
        <div style="cursor:pointer"> <span>©盘达学趣（武汉）科技有限公司 - </span> <span @click="jump()">关于我们</span> | <span @click="toConnect()">联系方式</span> </div>

          <p >工商注册地址：武汉东湖新技术开发区关东街道高新二路北辰光谷里二期8幢8号楼A单元24层元空间088 </p>

        </div>
      </div>
    </div>
    </div>
     <div class="mobail">

      <div class="header">
          <div class="homebtn" @click="backhome()">
          <img src="@/assets/images/homebtn.png" alt="" />
           </div>
           <div class="logo">
            <img src="@/assets/images/logo.png" alt="">
           </div>
      </div>
      <div class="content">
        <div class="contentBox">
          <div class="contentTitle">
            <h1>为什么这款刚上线就成为App Store教育榜冠军的APP更适合你？</h1>
        </div>
         <div class="contentaddTime">发布于 2023-07-25 18:44</div>
         <div class="contentView">
            <p class="juzhong">号外号外❗❗❗</p>
             <p class="juzhong">近日🐼熊猫村出特大新闻了！！</p>
              <p class="juzhong">村里的小伙伴们齐聚一堂庆祝这一时刻</p>
               <p class="juzhong">🎉🎉🎉🎉🎉</p>
                <p >近日，App Store付费榜单显示，熊猫在学在教育类榜单中排名处于领先位置且用户反馈好评不断，更是在App Store被打出均分4.8的高分。</p>
                <div class="juzhong">
                    <img src="@/assets/images/weixin/dengding.png" alt="">
                </div>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/dengding2.png" alt="">
                </div>
                <p class="juzhong">熊猫在学是家长激励孩子学习的法宝</p>
                <p class="juzhong">更是陪伴许多小朋友长大的英语神器</p>
                <p class="juzhong">不费爸妈，不焦头烂额，不花大钱上线下课</p>
                <p class="juzhong">轻轻松松打好英语基础</p>
                <h3 class="juzhong">快来看看可爱的达宝们怎么评价它吧</h3>
                <h5 class="juzhong">👇👇👇</h5>
                  <div class="juzhong">
                    <img src="@/assets/images/weixin/kankui1.jpg" alt="">
                    <img src="@/assets/images/weixin/fankui2.jpg" alt="">
                </div>
                <h3 class="juzhong">App Store评价：有趣、寓教于乐</h3>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/pinjia1.jpg" alt="">
                </div>
                 <p class="juzhong">就算看到这么多好评</p>
                  <p class="juzhong">你可能还是会有疑虑：真的就这么好用吗❓</p>
                   <p class="juzhong">不试试真的亏大发了</p>
                    <p class="juzhong">用了才知道学习英语简直不要太开心❗❗</p>
                    <h3 class="juzhong">👇👇👇</h3>
                    <div class="juzhong">
                    <img src="@/assets/images/weixin/logo.jpg" alt="">
                </div>
                 <h3 class="juzhong">高效记词</h3>
                 <p>熊猫在学的学习流程系统。每个词都有英选汉、汉选英、听音辨义、读词训练、单词拼写五个步骤，包括单词助记环节，app内根据艾宾浩斯记忆曲线为用户定制每日复习内容防止遗忘，一轮下来记熟单词小意思。
</p>
<div class="juzhong">
                    <img src="@/assets/images/weixin/daci.jpg" alt="">
                </div>
                 <h3 class="juzhong">单词随身听</h3>
                 <p>错词随身听，让你随时随地进入英语状态，轻松培养语感。分为错词、新词、生词三个板块，自动循环播放单词及其对应的例句，充分利用碎片化时间。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/suishenting.jpg" alt="">
                </div>
                 <h3 class="juzhong">任务机制

</h3>
                 <p>完成每日学习任务即可领取奖励，奖励可以用来购买家具装饰小屋还能解锁召唤新伙伴。每个孩子都喜欢被给予反馈，及时的正向反馈能成为孩子学习的持久动力源泉。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/renwu1.jpg" alt="">
                </div>
                 <h3 class="juzhong">Penny外教</h3>
                 <p class="juzhong">Penny外教帮翻译、纠语法、练口语、串词成句，成为你最信赖的老师。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/ailaos.png" alt="">
                     <!-- <img src="@/assets/images/weixin/ailaos2.jpg" alt=""> -->
                </div>
                  <h3 class="juzhong">趣味闯关</h3>
                 <p class="juzhong">英语趣味闯关，在游戏的过程中巩固所学单词，将学习与休闲相结合，打造轻松愉快学英语的环境。</p>
                 <div class="juzhong">
                    <img src="@/assets/images/weixin/huoban.png" alt="">

                </div>
                <div class="juzhong">
                    <img src="@/assets/images/weixin/youxi.jpg" alt="">

                </div>
                 <h2 class="juzhong">产品理念</h2>
                 <p>熊猫在学是一款主张学习＋正向激励的单词app，提倡通过多元化学习的形式把教育寄予在乐趣里，带给人趣味体验和益处。给用户带来高效记单词体验的同时，通过做任务、装饰小屋、趣味闯关等各个环节激励用户保持持久的学习劲头，逐渐将学习英语养成一种习惯。从痛苦乏味转为轻松有趣,从被动学习转为主动探索，让用户真正体验到学习的乐趣所在！</p>
                   <div class="juzhong">
                    <img src="@/assets/images/weixin/logo2.png" alt="">

                </div>
                 <p class="juzhong">学习从来都不是死记硬背</p>
                  <p class="juzhong">不是应付考试，不是迫于无奈</p>
                   <p class="juzhong">记单词不是根本目的</p>
                    <p class="juzhong">而是在寻找适合自己记忆方法的道路上</p>
                    <h3 class="juzhong">不断提高对英语的学习兴趣</h3>
                    <p class="juzhong">在学习中获得愉悦感和成就感</p>
                    <p class="juzhong">好的结果便会接踵而至😊😊😊</p>
                       <div class="juzhong">
                    <img src="@/assets/images/weixin/liule.jpg" alt="">

                </div>
                 <p class="juzhong">想亲身体验学习乐趣的宝子们</p>
                    <p class="juzhong">不要再犹豫啦❗❗</p>
                    <p class="juzhong">快来熊猫村一起学习玩耍吧</p>
                    <p class="juzhong">👏👏👏👏👏</p>
                    <h3 class="juzhong">
                        扫码下载app,学好英语很容易
                    </h3>
                     <div class="juzhong">
                    <img src="@/assets/images/weixin/xiazaima.png" alt="">

                </div>
                <h3 class="juzhong">
                        加入vx答疑群,变身熊猫小村民
                    </h3>
                     <div class="juzhong">
                    <img src="@/assets/images/weixin/weixinma.png" alt="">

                </div>

                <h3 class="juzhong">QQ群｜712390854</h3>
                  <h3 class="juzhong">小红书｜熊猫在学</h3>
                    <h3 class="juzhong">熊猫在学,有你更美好</h3>
                          <div class="juzhong">
                    <img src="@/assets/images/yidongduan/juese.png" alt="">

                </div>

         </div>

        </div>
      </div>
        <div class="footer2">
        <div class="info">
          <div class="box">
            <div>官方QQ群：712390854</div>
            <div class="link">
              <a
                href="https://beian.miit.gov.cn"
                >鄂ICP证：鄂B2-20200287
              </a>
              <a href="https://beian.miit.gov.cn"
                >鄂ICP备19017498号</a
              >
            </div>
            <div>
              <span @click="jump()">关于我们</span> | <span @click="toConnect()">联系方式</span>
            </div>
            <p>©盘达学趣（武汉）科技有限公司</p>
            <p>
                工商注册地址：武汉东湖新技术开发区关东街道高新二路北辰光谷里二期8幢8号楼A单元24层元空间088
            </p>
            <!-- <div>{{ $device }}</div> -->
          </div>
          <div class="box">
            <img src="../assets/images/yidongduan/qrcode.png" alt="" />
          </div>
        </div>
        <div>
          <!-- <img src="@/assets/images/fff.png" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
 name: "newsDetail",
  data() {
    return {

    };


},
  methods: {
    jump(){
      this.$router.push('/about')

    },
    toConnect(){
       this.$router.push('/connect')

    },
    backhome(){
       this.$router.push('/')

    },

    toNewsDetail(){
       this.$router.push('/NewsDetail')
    }



  },



}






</script>

<style lang='scss' scoped>
@media screen and (min-width: 901px) {
.mobail{
  display: none;
}
.main{

    height: 100%;
    margin: 0;
    padding:  0;

background-color: #fae8ba;
.header{
    width: 100%;
    height: 220rem;
    padding: 10rem;
       .home{
        width: 140rem;
        height: 40rem;
        margin: 0rem 0 0 20rem;
        img{
          width: 100%;
        }
      }
      .logo{
        margin: 0 auto;
        width: 300rem;
        img{
          width: 100%;
        }
      }
}
.content{
    width: 100%;
    // height: 13600rem;
   height: auto ;
    // background-color: pink;
    padding: 0 300rem;
    color: #7b5431;
    font-family: KingnamMaiyuanBeta;
    .contentBox{
//  height: auto ;
         .contentTitle{
        // width: 1200rem;
        font-size: 28rem;
        line-height: 78rem;
         margin: 0 auto;
    }
    .contentaddTime{
        text-align: center;
    }
    .juzhong{
        text-align: center;
    }
    .contentView{
        width: 1200rem;
        // height: 600rem;
        font-size: 22rem;
        margin: 20rem auto;
        h3,h2{
            margin: 40rem 0 40rem;
        }
        p,div{
            margin-top: 4rem;
            img{
                max-width: 600rem;
            }

        }

    }

    }

}

    .footer {
      width: 100%;
      height: 276rem;
      // margin-top: 10rem;
      // position: absolute;
      // top: 5300rem;
      background-color: #e4b582;
      a {
        text-decoration: none;
        color: #fff;
      }
      .cominfo {
        background-color: #e4b582;
        width: 100%;
        height: 220rem;
        .company {
          width: 1288rem;
          margin: 0 auto;
          background-color: #e4b582;
          color: #fff;
          // font-family: KingnamMaiyuanBeta;
          font-size: 19rem;
          line-height: 38rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .text2 {
            width: 1100rem;
            height: 18rem;
            margin-bottom: 10rem;
            text-align: center;
            white-space: nowrap;
          }
          .text1 {
            white-space: nowrap;
              text-align: center;
          }
          .logo {
            height: 71rem;
            margin-top: 30rem;

            .logoPic {
              img {
                vertical-align: middle;
              }

              span {
                line-height: 70rem;
                height: 70rem;
                margin-left: 7rem;
                margin-right: 20rem;
              }
            }
            .right {
              margin-left: 20rem;
            }
          }
          // .address{
          //   width: 100%;

          // }
        }
      }
      .phone {
        width: 100%;
        height: 333rem;
        background-color: #e4b582;
        div {
          font-size: 36rem;
          font-family: KingnamMaiyuanBeta;
          line-height: 38rem;
          color: #7b5431;
          width: 370rem;
          height: 200rem;
          position: absolute;
          margin: 98rem 200rem 0 150rem;
          p {
            margin-bottom: 40rem;
          }
        }
        .logoSmall {
          margin-left: 1200rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
        .touch {
          margin-left: 1480rem;
          margin-top: 60rem;
          width: 175rem;
          img {
            width: 100%;
          }
          p {
            text-align: center;
          }
        }
      }
    }
}
}
@media screen and (max-width: 900px) {
  .main {
     height: 100%;
    margin: 0;
    padding:  0;

background-color: #fae8ba;
    .pc {
      display: none;
    }


    .mobail {
      .header{
        width: 100%;
        height: 600rem;
        padding: 50rem 0 0 50rem;
        .homebtn{
          width: 400rem;
          img{
            width: 100%;
          }
        }
        .logo{
          width: 800rem;
          margin: 0 auto 20rem;

          img{
            width: 100%;

          }
        }
         }

      .content{
        width: 100%;
        height: auto;
          padding: 0 100rem;
    color: #7b5431;
      font-family: KingnamMaiyuanBeta;
    // background-color: #fff;
    .contentTitle{
      font-size: 50rem;
       line-height: 120rem;
    }
    .contentaddTime{
      font-size: 38rem;
      text-align: center;
    }
     .juzhong{
        text-align: center;
    }
    .contentView{
      margin:40rem auto;
      font-size: 80rem;
       h3,h2{
            margin: 80rem 0 80rem;
        }
        p,div{
            margin-top: 40rem;
            img{
                max-width: 1400rem;
            }

        }

    }

      }

      .footer2 {
        margin-top: 5rem;
        background-image: url(../assets/images/yidongduan/beijing2.png);
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        .msgBox22 {
          margin-top: 150rem;
          .my-swipe {
            width: 1920rem;
            // width: 100%;
            // background-color: red;
            .van-swipe-item {
              display: flex;
              justify-content: center;

              // background-color: #39a9ed;

              img {
                // width: 1000rem;
                width: 70%;
                // height: 800rem;
                object-fit: contain;
              }
            }
          }
        }
        .info {
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            margin: 150rem 10rem 10rem 20rem;

            display: flex;
            flex-direction: column;
            // align-items: center;
            font-size: 60rem;
            padding-bottom: 100rem;
            .link {
              display: flex;
              flex-direction: column;
            }
            a {
              text-decoration: none;
              color: #fff;
            }
            img {
              width: 400rem;
              object-fit: contain;
              margin-left: 20rem;
            }
          }
        }
      }
    }
  }
}
</style>
